import React, { useState, useEffect } from "react";
import BackgroundImage from "gatsby-background-image";
import { GiNewspaper } from "react-icons/gi";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import LazyLoad from "react-lazyload";

import { Link, useI18next } from "gatsby-plugin-react-i18next";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

const recaptchaRef = React.createRef();

function Newsletter() {
  const { t } = useTranslation();
  const c = useI18next();
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const {
    register,
    handleSubmit,
    watch,
    errors,
    formState,
    setValue,
    getValues,
  } = useForm();
  const { isSubmitted, isValid } = formState;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const onSubmit = (data) => {
    setIsSubmitting(true);
    data["g-recaptcha-response"] = recaptchaValue;
    fetch("/api/newsletter", {
      method: "POST",
      body:
        "email=" +
        encodeURIComponent(data.email) +
        "&g-recaptcha-response=" +
        data["g-recaptcha-response"],
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      redirect: "follow",
    })
      .then((resp) => {
        console.log(resp);
        if (resp.redirected) {
          window.location.href = resp.url;
        }
      })
      .catch((error) => {
        console.log(error);
        setSuccess("false");
        setError("Internal Error");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };
  function iss(isSubmitting) {
    return isSubmitting;
  }

  useEffect(() => {
    register({ name: "captchaToken" }, { required: true });
  });
  function onChange() {
    console.log("recaptcha");
    setRecaptchaValue(recaptchaRef.current.getValue());
    setValue("captchaToken", recaptchaRef.current.getValue());
    clearErrors(["captchaToken"]);
  }

  useEffect(() => {
    register({ name: "captchaToken" }, { required: true });
  });
  return (
    <LazyLoad offset={500}>
      <div className="flex flex-col flex-1 md:justify-center max-w-4xl mx-auto px-4 py-8 md:p-8 w-full">
        <div className="mx-auto shadow rounded to w-full md:w-3/4 border-solid border-t-4 bg-blue-700 p-6 my-2">
          <div className="flex justify-between items-center" />
          <GiNewspaper
            className="float-right"
            color="rgb(255, 255, 255, 0.3)"
            size={42}
          />
          <h3 className="text-2xl text-white font-black font-sans leading-normal">
            <Trans i18nKey="news_free">
              Newsletter and <span className="text-yellow-500">free</span>{" "}
              shapefiles
            </Trans>
          </h3>
          <p className="my-3 text-white text-bold  tracking-wide font-sans leading-normal">
            <Trans i18nKey="news_text">
              My email list is pretty simple: one email for each new post. If
              you join over 5,000 subscribers from Harvard, Stanford, Google,
              McKinsey... you'll receive as a welcome gift the 100+ shapefiles
              of Mexico you will ever need (It's more than 5GB of data and
              totally free)
            </Trans>
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <p id="input_desc" hidden>
              Enter Email
            </p>
            <label
              id="newsletterLabel"
              htmlFor="newsletterEmail"
              className="hidden"
            >
              Email
            </label>
            <input
              aria-describedby="input_desc"
              aria-labelledby="newsletterLabel"
              id="newsletterEmail"
              type="text"
              name="email"
              autocomplete="email"
              className={
                errors.email
                  ? "rounded-sm border-solid border w-full md:w-3/5 rounded px-3 py-2 errorInput"
                  : "rounded-sm border-solid border w-full md:w-3/5 rounded px-3 py-2"
              }
              placeholder="Email"
              ref={register({
                required: "Required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i,
                  message: "invalid email address",
                },
              })}
            />
            {/*  <span className="text-black font-bold pl-4 italic">
            {errors.email && errors.email.message}
          </span> */}
            <br />
            <div style={{ height: "100px", marginBottom: "10px" }}>
              <ReCAPTCHA
                sitekey="6LdhprAZAAAAAONu3rfxTPNGCIjjHbH06dqVerDd"
                className="py-3"
                ref={recaptchaRef}
                onChange={onChange}
                onErrored={() => {}}
                hl={c.language}
              />
            </div>
            {errors.captchaToken && (
              <span className="errorInput text-black font-bold p-4 italic">
                <span><Trans i18nKey="Recaptcha Required">Recaptcha Required</Trans></span>
                <br />
              </span>
            )}
            <button
              type="submit"
              disabled={!isValid ? false : isSubmitting || isSubmitted}
              className="border-2 border-gray-800 bg-orange-600 hover:bg-orange-500 text-white shadow hover:shadow-lg font-sans font-bold px-3 py-4  rounded w-full md:w-1/4 leading-none"
            >
              {!isValid
                ? t("Sign Up")
                : isSubmitting || isSubmitted
                ? t("Sending...")
                : t("Sign Up")}
            </button>
          </form>
        </div>
      </div>
    </LazyLoad>
  );
}

export default Newsletter;
